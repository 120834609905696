import React from 'react';
import './App.css';
import Header from './components/Header';
import Content from './components/Content';
import { Web3ReactProvider } from "@web3-react/core";
import { ethers } from "ethers";
function App() {
  //style
  let style = {
    backgroundColor: "#1c1a1e",
    height: "100%",
    minHeight: "100vh",
    padding: "0",
    margin: "0",
    paddingLeft: "5%",
    paddingRight: "5%",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center"
  }

  //web3 - usage of any because library also does it kekw
  const getLibrary = (provider:any) => {
    const library = new ethers.providers.Web3Provider(provider);
    library.pollingInterval = 8000; // frequency provider is polling
    return library;
  };

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <div className="App" style={style}>
        <Header></Header>
        <Content></Content>
      </div>
    </Web3ReactProvider>
  );
}

export default App;
