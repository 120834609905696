import React from 'react'
import NavBar from './NavBar';

export default function Header() {
   let style = {
       width: "100%",
       height: "10vh",
       zIndex: "9999999999"
   }
  return (
    <div style={style}>
        <NavBar></NavBar>
    </div>
  )
}