import React from 'react'
import MintCard from './MintCard'
import { useWeb3React } from "@web3-react/core";

export default function Content() {
  //styles
  let headingStyle = {
    fontSize: "calc(2vh + 1vw)",
    fontFamily: "MonsterMashOutline",
    textAlign: "center" as "center"
  }
  let contentStyle = {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    textAlign: "center" as "center",
    maxWidth: "800px",
  }

  const {
    library,
    chainId,
    account,
    activate,
    deactivate,
    active
  } = useWeb3React();
  
  return (
    <div style={contentStyle}>
      <div style={headingStyle}><h1>MINT</h1></div>
      <MintCard {...{library,chainId,account,activate,deactivate,active}}></MintCard>
    </div>
  )
}