/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";
import { MintCardProps } from "./MintCard.Types";
import { connectors } from "../modules/connectors";
import metamaskImg from "../assets/mm.png";
import walletconnectImg from "../assets/wc.png";
import Samsara from "../hardhatabi/SamsaraFree.json";
const { MerkleTree } = require("merkletreejs");
const whitelist = require("../whitelist/whitelist.json");
const keccak256 = require("keccak256");
const { ethers } = require("ethers");

//styles
let mintCardStyle = {
  display: "flex",
  flexDirection: "column" as "column",
  alignItems: "center",
  backgroundColor: "rgb(42 37 46)",
  border: "1px solid #f40c54",
  color: "white",
  minWidth: "33vw",
  maxWidth: "600px",
  marginBottom: "25px",
};

let cardContentStyle = {
  display: "flex",
  flexDirection: "column" as "column",
  alignItems: "center",
};
let textFieldStyle = {
  height: "52px",
  borderRadius: "4px",
  margin: "5px",
  fontSize: "2em",
  textAlign: "center" as "center",
  flexGrow: 1,
  marginLeft: "15px",
};

let textFieldError = {
  height: "52px",
  borderRadius: "4px",
  margin: "5px",
  fontSize: "2em",
  textAlign: "center" as "center",
  border: "5px solid #f40c54",
  color: "#f40c54",
  flexGrow: 1,
  marginLeft: "15px",
};

let buttonStyle = {
  color: "white",
  backgroundColor: "#f40c54",
  margin: "5px",
  height: "56px",
  flexGrow: 0.2,
  fontSize: "1.5em",
  marginRight: "15px",
  zIndex: "0",
};

let mintButtonStyle = {
  color: "white",
  backgroundColor: "#f40c54",
  margin: "5px",
  height: "56px",
  fontSize: "1.5em",
  marginRight: "15px",
  zIndex: "0",
  width: "135px",
};

let cardActionsStyle = {
  display: "flex",
  flexDirection: "column" as "column",
  width: "100%",
  justifyContent: "space-between",
};

let formStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
};

let cardHeaderStyle = {
  width: "100%",
};

let redCircle = {
  width: "15px",
  height: "15px",
  backgroundColor: "#f40c54",
  borderRadius: "25px",
  marginRight: "25px",
  marginLeft: "auto",
};

let greenCircle = {
  width: "15px",
  height: "15px",
  backgroundColor: "#35E5C3",
  borderRadius: "25px",
  marginRight: "25px",
  marginLeft: "auto",
};

let modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 400,
  bgcolor: "black",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  border: "1px solid #35E5C3",
  borderRadius: "4px",
};

let walletIcon = {
  width: "25px",
  height: "25px",
  borderRadius: "3px",
  marginRight: "15px",
};

let walletButtonStyle = {
  display: "flex",
  flexDirection: "row" as "row",
  alignItems: "center" as "center",
  justifyContent: "center",
  textAlign: "center" as "center",
  color: "black",
  backgroundColor: "white",
  margin: "5px",
  height: "56px",
  width: "80vw",
  maxWidth: "300px",
  flexGrow: 0.2,
  fontSize: "1em",
  zIndex: "0",
};

let progressStyle = {
  color: "white",
};

let infoStyle = {
  display: "flex",
  maxWidth: "80vw",
  flexDirection: "column" as "column",
  alignItems: "center",
  textAlign: "center" as "center",
  wordBreak: "break-all" as "break-all",
};
let connected = {
  color: "#35E5C3",
};

let notConnected = {
  color: "#f40c54",
};

export default function MintCard(props: MintCardProps) {
  //web3 state
  const { active, account, library, chainId, activate } = props;
  const [message, setMessage] = useState("");
  const [initContract, setInitContract] = useState<any>("");
  useEffect(() => {
    //initial provider to show totalSupply to user before connecting
    let address = Samsara.address;
    let abi = Samsara.abi;
    const urls = [
      "https://sepolia.infura.io/v3/e11543df369648d7b92348d011610ef8",
    ];
    const initProviderUrl = urls[Math.floor(Math.random() * urls.length)];
    console.log(initProviderUrl);
    const initProvider = new ethers.providers.JsonRpcProvider(initProviderUrl);
    const initContract = new ethers.Contract(address, abi, initProvider);
    setInitContract(initContract);

    initContract.totalSupply().then((data: any) => {
      let res = ethers.utils.formatUnits(data, "0");
      settotalSupply(res);
      // check minting mode
      initContract.publicMinting().then((data: any) => {
        if (data === true) {
          // public mint
          setMaxAmount(10);
          setMintMode("public");
          return;
        }
        initContract.wlMinting().then((data: any) => {
          if (data === true) {
            // whitelist mint
            setMaxAmount(4);
            setMintMode("wl");
            return;
          }
          // minting disabled
          setMaxAmount(0);
          setMintMode("");
        });
      });
    });
  }, []);

  const [totalSupply, settotalSupply] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);
  const [mintMode, setMintMode] = useState("");
  //provider web3-react
  const setProvider = (type: any) => {
    window.localStorage.setItem("provider", type);
  };
  useEffect(() => {
    const provider = window.localStorage.getItem("provider");
    if (provider) activate((connectors as any)[provider]);
  }, []);

  //mint
  const mint = async (addr: string | null | undefined) => {
    sendTransaction();
  };

  const getProof = (address: any) => {
    const whitelistAddresses = whitelist.map((el: string) => el.toLowerCase());
    address = address.toLowerCase();
    const leafNodes = whitelistAddresses.map((addr: string) => keccak256(addr));
    const tree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
    const root = tree.getHexRoot();
    let hexProof = [];
    let index = whitelistAddresses.indexOf(address);
    if (index > -1) {
      //if in whitelist, check if hashed address equals result of index in leaf nodes
      const claimingAccount = leafNodes[index];
      hexProof = tree.getHexProof(claimingAccount);
    }
    return { hexProof: hexProof, root: root };
  };

  async function sendTransaction() {
    const signer = initContract.connect(library.getSigner());
    let publicMint = await initContract.publicMinting();
    let wlMint = await initContract.wlMinting();
    if (publicMint === false && wlMint === false) {
      setloading(false);
      setMessage("Minting is not active!");
      setseverity("error");
      setOpenSnackbar(true);
      return;
    }
    if (publicMint === true) {
      await mintPublic(signer, initContract);
      return;
    }
    if (wlMint === true) {
      try {
        const proof: string[] = getProof(account)["hexProof"];
        await mintWl(signer, initContract, proof);
      } catch (error: any) {
        handleMintError(error);
        return;
      }
    }
  }

  const mintPublic = async (signer: any, contract: any) => {
    try {
      let tx = await signer.mintPublic(parseInt(amount), {
        value: ethers.utils.parseEther(
          `${(parseInt(amount) * parseFloat(price)).toFixed(2)}`
        ),
      });
      await tx.wait();
      setMessage("Mint success!");
      setseverity("success");
      setOpenSnackbar(true);
      setloading(false);
      contract.totalSupply().then((data: any) => {
        let res = ethers.utils.formatUnits(data, "0");
        settotalSupply(res);
      });
      let amountNum = parseInt(amount);
      let newAmount: number = +accMinted + +amountNum;
      setaccMinted(newAmount);
    } catch (error: any) {
      handleMintError(error);
    }
  };

  const mintWl = async (signer: any, contract: any, proof: string[]) => {
    try {
      let tx = await signer.mintWhitelist(parseInt(amount), proof, {
        value: ethers.utils.parseEther(
          `${(parseInt(amount) * parseFloat(price)).toFixed(2)}`
        ),
      });
      await tx.wait();
      setMessage("Mint success!");
      setseverity("success");
      setOpenSnackbar(true);
      setloading(false);
      contract.totalSupply().then((data: any) => {
        let res = ethers.utils.formatUnits(data, "0");
        settotalSupply(res);
      });
      let amountNum = parseInt(amount);
      let newAmount: number = +accMinted + +amountNum;
      setaccMinted(newAmount);
    } catch (error: any) {
      handleMintError(error);
    }
  };

  const handleMintError = (error: any) => {
    try {
      console.log(error.message);
      let newError = error.message
        .split('"message":"execution reverted:')[1]
        .split('","data":')[0];
      if (newError.includes("Invalid proof")) {
        newError = "You are not whitelisted!";
      }
      setloading(false);
      setMessage(newError);
      setseverity("error");
      setOpenSnackbar(true);
    } catch {
      try {
        setloading(false);
        setMessage(error.data.message);
        setseverity("error");
        setOpenSnackbar(true);
      } catch (error: any) {
        setloading(false);
        setMessage("Oops, something went wrong!");
        setseverity("error");
        setOpenSnackbar(true);
      }
    }
  };
  const price = "0.00";
  const minAmount = 1;
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleCloseSnackbar = () => setOpenSnackbar(false);
  type AlertColor = "success" | "info" | "warning" | "error";
  const [severity, setseverity] = useState("warning" as AlertColor | undefined);
  //form validation front end for user convenience
  const validate = (e: string) => {
    setAmount(e);
    setAmountError(false);

    if (
      e === "" ||
      parseInt(e) < minAmount ||
      parseInt(e) > maxAmount - accMinted
    ) {
      setAmountError(true);
    }
  };
  //submit event, mint trigger, button ui, networks
  const [loading, setloading] = useState(false);
  const networks = {
    31337: "Localhost network",
    1: "Ethereum mainnet",
    5: "Sepolia testnet",
  };
  const networkId = 5;
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (chainId !== networkId) {
      setMessage(
        `Please make sure you are connected to the ${networks[networkId]}!`
      );
      setseverity("warning");
      setOpenSnackbar(true);
      return;
    }
    if (!amountError && amount !== "") {
      try {
        setloading(true);
        await mint(account);
      } catch (error: any) {
        setMessage("Oops! Something went wrong!");
        setloading(false);
        setseverity("error");
        setOpenSnackbar(true);
      }
    }
  };
  //show user how many have been minted using the acc
  const [accMinted, setaccMinted] = useState<number>(0);
  const getUserMinted = async () => {
    let data = 0;
    if (mintMode === "public") {
      data = await initContract.publicMints(account);
    }
    if (mintMode === "wl") {
      data = await initContract.wlMints(account);
    }
    try {
      let res = ethers.utils.formatUnits(data, "0");
      setaccMinted(res);
    } catch (error: any) {
      console.log(error);
      setaccMinted(0);
    }
  };
  useEffect(() => {
    //get minted amount from contract
    if (typeof account === "undefined") {
      return;
    }
    getUserMinted();
  }, [account, mintMode]);

  return (
    <div>
      <Card style={mintCardStyle}>
        <CardHeader
          action={<div style={active ? greenCircle : redCircle}></div>}
          style={cardHeaderStyle}
        />
        <CardContent style={cardContentStyle}>
          {totalSupply >= 5000 ? (
            <h1>SOLD OUT!</h1>
          ) : (
            <h1>Minted: {totalSupply} / 5000</h1>
          )}
          <h1>Price: FREE</h1>
        </CardContent>
        <CardActions style={cardActionsStyle}>
          {active ? (
            <form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
              style={formStyle}
            >
              <input
                type="number"
                min={minAmount}
                max={maxAmount}
                placeholder="Enter Quantity"
                id="quantity"
                style={amountError ? textFieldError : textFieldStyle}
                onChange={(e) => validate(e.target.value)}
              ></input>
              <Button
                type="submit"
                variant="contained"
                size="large"
                style={mintButtonStyle}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={20} style={progressStyle} />
                ) : (
                  "MINT"
                )}
              </Button>
            </form>
          ) : (
            <div>
              <Button
                variant="contained"
                size="large"
                style={buttonStyle}
                onClick={handleOpen}
              >
                CONNECT WALLET
              </Button>
            </div>
          )}
        </CardActions>
      </Card>
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <Button
            variant="contained"
            size="large"
            style={walletButtonStyle}
            onClick={() => {
              activate(connectors.walletConnect);
              setProvider("walletConnect");
              handleClose();
            }}
          >
            <img
              src={walletconnectImg}
              style={walletIcon}
              alt="walletconnect"
            ></img>
            Wallet Connect
          </Button>
          <Button
            variant="contained"
            size="large"
            style={walletButtonStyle}
            onClick={() => {
              activate(connectors.injected);
              setProvider("injected");
              handleClose();
            }}
          >
            <img src={metamaskImg} style={walletIcon} alt="metamask"></img>
            Metamask
          </Button>
        </Box>
      </Modal>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert severity={severity}>{message}</Alert>
      </Snackbar>
      <div style={infoStyle}>
        {active ? (
          <p style={connected}>
            Account:<br></br>
            {account}
            <br></br>
            <br></br>You minted &#40;{mintMode}{" "}
            {mintMode ? "mint" : "minting disabled "}&#41;: {accMinted} /{" "}
            {maxAmount}
          </p>
        ) : (
          <p style={notConnected}>Please connect your wallet!</p>
        )}
      </div>
    </div>
  );
}
