import React from 'react'
import DiscordIcon from "./DiscordIcon"
import TwitterIcon from "./TwitterIcon"
import OSIcon from "./OSIcon"
import logoImg from "../assets/logo.png"
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from './CloseIcon'
import "./NavBarStyles.css"

export default function NavBar() {
  // Menu Items
  let menuItems = [
    {text: "About", link: "https://samsararebels.com/#about"},
    {text: "Project", link: "https://samsararebels.com/#project"},
    {text: "Origins", link: "https://samsararebels.com/#community"},
    {text: "FAQs", link: "https://samsararebels.com/#faqs"},
    {text: "Team", link: "https://samsararebels.com/#team"},
    {text: "STORY & WIKI", link: "https://samsararebels.com/samsara-wiki/", class: "storyMenuItem"}
  ]

  // Navbar
  let iconStyle = {
    margin: "10px", 
  }

  const [isOpen, setisOpen] = React.useState(false);
  const [discordColor, setdiscordColor] = React.useState("white");
  const [twitterColor, settwitterColor] = React.useState("white");
  const [osColor, setosColor] = React.useState("white");

  const toggleDrawer = () => {
    setisOpen(!isOpen);
  }
  
  return (
    <div className='navbar'>
      <div className='parentDiv'>
          <div className='logoDiv'>
            <a href="http://samsararebels.com/" target="_blank" rel="noreferrer">
              <img src={logoImg} className="logo" alt="Saṃsāra logo"></img>
            </a>
          </div>
        <div className="menuDiv">
          {menuItems.map(d => (
            <a href={d.link} key={d.text} className={d.class} target="_blank" rel='noreferrer'><p>{d.text}</p></a>
          ))}
        </div>
        <div className="iconsDiv">
          <a href="https://opensea.io/" target="_blank" rel="noreferrer"
           onMouseEnter={()=> {setosColor("#35E5C3")}}
           onMouseLeave={()=> {setosColor("white")}}>
             <OSIcon style={iconStyle} width={21} height={21} color={osColor}></OSIcon>
          </a>
          <a href="https://discord.gg/samsara" target="_blank" rel="noreferrer"
           onMouseEnter={()=> {setdiscordColor("#35E5C3")}}
           onMouseLeave={()=> {setdiscordColor("white")}}>
             <DiscordIcon style={iconStyle} width={21} height={21} color={discordColor}></DiscordIcon>
          </a>
          <a href="https://twitter.com/SamsaraRebels" target="_blank" rel="noreferrer"
           onMouseEnter={()=> {settwitterColor("#35E5C3")}}
           onMouseLeave={()=> {settwitterColor("white")}}>
            <TwitterIcon style={iconStyle} width={21} height={21} color={twitterColor}></TwitterIcon>
          </a>
        </div>

        {/*Mobile Hamburger*/}
        <div className="hamburger">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
          >
            {isOpen?<CloseIcon></CloseIcon>:<MenuIcon />}
          </IconButton>
        </div>
      </div>

      {/*Dropdown Menu*/}
      {isOpen? <div className='dropdown'>
          {menuItems.map(d=>(
            <a href={d.link} key={d.text} target="_blank" rel='noreferrer'><h3>{d.text}</h3></a>
          ))
          }
      </div>
      :<div></div>}
    </div>
  )
}